import { Flex, FlexAlignCenter, FlexCol, FlexColAlignCenterLgMin } from 'components/primitives/flex';
import styled, { css } from 'styled-components';
import { mediaMMdMax } from 'helpers/breakpoints';
import { determineStaticPreviewImage } from 'utils/images';
import { useIsTextClamped } from './hooks';
import StarRating, { Stars } from './StarRating';
import { defaultPic } from '../../../helpers';
import { AnimatedSkeleton, AnimatedSkeletonCircle, Badge } from './common';

export const UserCardReviewerSkeleton = ({ isInModal = false }) => (
  <CardReviewerContainer isInModal={isInModal}>
    <MainInfo centered={false} gap="12px" width="100%">
      <Header>
        <AnimatedSkeletonCircle size="60px" />
        <div>
          <AnimatedSkeleton width="160px" height="20px" margin="0 0 4px" />
          <AnimatedSkeleton width="100px" height="10px" />
        </div>
      </Header>
      <Stars rating={0} smaller />
      <div>
        <AnimatedSkeleton width="100%" height="16px" />
        <AnimatedSkeleton width="100%" height="16px" margin="6px 0" />
        <AnimatedSkeleton width="90%" height="16px" />
      </div>
    </MainInfo>
  </CardReviewerContainer>
);

export const UserCardReviewer = ({
  profilePhotoUrl,
  text,
  reviewerName,
  reviewDate,
  rating,
  clampText = true,
  onReadMoreClick = () => {},
  className = '',
  isInModal = false,
}) => {
  const [isTextClamped, textRef] = useIsTextClamped(clampText);
  return (
    <CardReviewerContainer className={className} isInModal={isInModal}>
      <MainInfo centered={false} gap="12px" width="100%">
        <Header>
          <Picture url={profilePhotoUrl} userName={reviewerName} />
          <div>
            <Name fontSize="16px">{reviewerName}</Name>
            <Date>{reviewDate}</Date>
          </div>
        </Header>
        <Stars rating={rating} smaller />

        {/* @ts-ignore */}
        {clampText ? <ClampText ref={textRef}>{text}</ClampText> : <Text>{text}</Text>}
        {isTextClamped && <ReadMore onClick={onReadMoreClick}>Read more</ReadMore>}
      </MainInfo>
    </CardReviewerContainer>
  );
};

const CardReviewerContainer = styled(Flex)<{ isInModal: boolean }>`
  ${({ isInModal }) =>
    isInModal
      ? css`
          ${mediaMMdMax} {
            padding: 24px 0;

            &:not(:last-child) {
              border-bottom: 1px solid #dbdfe5;
            }
          }
        `
      : css`
          ${mediaMMdMax} {
            border: 1px solid #dbdfe5;
            border-radius: 9px 9px 12px 12px;
            padding: 22px;
            height: calc(100% - 44px - 2px);
          }
        `};
`;

export const UserCardCrewMember = ({
  memberName,
  rating,
  reviewCount,
  userId,
  profilePhotoUrl,
  text,
  isTopOwner = false,
  isNewCaptain = false,
  isNewOwner = false,
  countText = undefined,
}) => (
  <MainInfo centered={false}>
    <Header>
      <Picture userName={memberName} url={profilePhotoUrl} size="82px" data-testid="pdp-your-crew-user-card-photo" />
      <div>
        <Name data-testid="pdp-your-crew-user-card-name">
          {memberName}
          <Badge
            topOwner={isTopOwner}
            newOwner={isNewOwner}
            newCaptain={isNewCaptain}
            data-testid="pdp-your-crew-user-card-new-top-badge"
          />
        </Name>
        <div data-testid="pdp-your-crew-user-card-rating">
          {reviewCount > 0 && <StarRating rating={rating} countText={countText} reviewCount={reviewCount} short />}
        </div>
      </div>
    </Header>
    {text && <ClampText data-testid="pdp-your-crew-user-card-text">{text}</ClampText>}

    <SeeProfileLink href={`/users/${userId}`} target="_blank" data-testid="pdp-your-crew-user-card-see-profile">
      See profile
    </SeeProfileLink>
  </MainInfo>
);

export function UserCardOwner({
  rating,
  reviewCount,
  profilePhotoUrl,
  ownerName,
  isTopOwner = false,
  isNewOwner = false,
}) {
  return (
    <CardOwnerContainer centered smallGap>
      <Header data-testid="pdp-boat-header-boat-info-owner-photo">
        <Picture userName={ownerName} url={profilePhotoUrl} size="72px" />
      </Header>
      <FlexColAlignCenterLgMin>
        <OwnedByText>Owned by</OwnedByText>
        <Name fontSize="14px" data-testid="pdp-boat-header-boat-info-owner-name">
          {ownerName}
          <Badge
            topOwner={isTopOwner}
            newOwner={isNewOwner}
            iconOnly
            data-testid="pdp-boat-header-boat-info-owner-new-top-badge"
          />
        </Name>
        <div data-testid="pdp-boat-header-boat-info-owner-rating">
          {reviewCount > 0 && (
            <StarRating rating={rating} countText="rating" reviewCount={reviewCount} fontSize="11px" short />
          )}
        </div>
      </FlexColAlignCenterLgMin>
    </CardOwnerContainer>
  );
}

export function CaptainUserCard({ name, profilePhotoUrl, reviewCount, rating, userId, isNewCaptain }) {
  return (
    <MainInfo>
      <Header>
        <Picture userName={name} url={profilePhotoUrl} size="64px" />
        <div>
          <Name fontSize="16px" fWeight="700">
            {name}
          </Name>
          <Badge newCaptain={isNewCaptain} my="2px" />
          {reviewCount > 0 && <StarRating rating={rating} reviewCount={reviewCount} short hideCountText />}
          <SeeProfileLink href={`/users/${userId}`} target="_blank" fontSize="12px">
            View profile
          </SeeProfileLink>
        </div>
      </Header>
    </MainInfo>
  );
}

const MainInfo = styled(FlexCol)<{ smallGap?: boolean; centered?: boolean }>`
  gap: ${({ smallGap, gap }) => gap || (smallGap ? '0' : '20px')};
  ${({ centered }) =>
    centered &&
    css`
      align-items: center;
    `};
`;

const Header = styled(FlexAlignCenter)`
  gap: 20px;
`;

const Picture = styled(({ userName, url, ...other }) => (
  <img
    alt={userName}
    src={determineStaticPreviewImage(url, userName)}
    onError={({ target }) => {
      // eslint-disable-next-line no-param-reassign
      (target as HTMLImageElement).src = defaultPic(userName);
    }}
    {...other}
  />
))`
  border-radius: 50%;
  width: ${({ size = '60px' }) => size};
  height: ${({ size = '60px' }) => size};
`;

const Name = styled(FlexAlignCenter)<{ fontSize?: string; fWeight?: string }>`
  font-size: ${({ fontSize }) => fontSize || '18px'};
  font-weight: ${({ fWeight }) => fWeight || '600'};
  line-height: 1.3333;
  gap: 8px;
`;

const Date = styled.div`
  font-size: 12px;
  line-height: 16px;
`;

const Text = styled.div`
  font-size: 16px;
  line-height: 22px;
`;

const SeeProfileLink = styled.a<{ fontSize?: string }>`
  font-size: ${({ fontSize }) => fontSize || '16px'};
  font-weight: 500;
  line-height: 2;
  color: #0751c2;
`;

const OwnedByText = styled.div`
  font-size: 9px;
  font-weight: 600;
  line-height: 24px;
  color: #8e9697;
  text-transform: uppercase;
`;

const ClampText = styled(Text)`
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
  overflow: hidden;
`;

const ReadMore = styled.span`
  font-weight: 500;
  color: #0751c2;
  cursor: pointer;
`;

const CardOwnerContainer = styled(MainInfo)`
  ${mediaMMdMax} {
    flex-direction: row;
    align-items: center;
    gap: 14px;
  }
`;
