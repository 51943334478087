import { Fragment, useCallback, useState } from 'react';
import styled from 'styled-components';
import InfiniteScroll from 'react-infinite-scroll-component';
import Button from 'components/ui/Button/Button';
import { FlexAlignCenterSpaceBetween, FlexCenterBoth, FlexCol } from 'components/primitives/flex';
import { mediaMMdMin } from 'helpers/breakpoints';
import { useRouter } from 'next/router';
import { useAtom, useAtomValue, useSetAtom } from 'jotai';
import {
  bookingCaptainIdAtom,
  bookingCaptainPublicIdAtom,
  selectedBookingPackageAtom,
} from 'components/forms/bookingWidget/jotaiStore';
import { useCloseModal } from 'components/remodal/v2/hooks';
import LoadingSpinner from 'components/loading/LoadingSpinner';
import { useUserDetails } from 'auth/mutations/userHooks';
import boatsetterPaths from 'utils/boatsetterPaths';
import { Separator, SubHeading } from '../common';
import { CaptainUserCard } from '../UserCard';
import InviteCaptainForm from '../forms/InviteCaptainForm';
import { useBoatDetails, useInfiniteCaptains } from '../hooks';

export const InfiniteLoader = () => (
  <Container>
    <FlexCenterBoth padding="20px">
      <LoadingSpinner bg color="#72D4BA" />
    </FlexCenterBoth>
  </Container>
);

export default function ChangeCaptainModal({
  boatId,
  onSelectCaptain,
}: {
  boatId: string;
  onSelectCaptain?: (captainId: string) => void;
}) {
  const router = useRouter();
  const [inviteFormVisible, setInviteFormVisible] = useState(false);
  const { boatDetails = {} } = useBoatDetails(boatId);
  const { isAuthenticated } = useUserDetails();
  const [bookingCaptainId, setBookingCaptainId] = useAtom(bookingCaptainIdAtom);
  const setBookingCaptainPublicId = useSetAtom(bookingCaptainPublicIdAtom);

  const selectedBookingPackage = useAtomValue(selectedBookingPackageAtom);
  const closeModal = useCloseModal();

  const { fetchNextPage, pages, isLoading, hasNextPage } = useInfiniteCaptains(
    boatId,
    selectedBookingPackage?.package_type === 'captained',
    boatDetails.captain_network_enabled
  );

  const toggleInviteForm = useCallback(() => {
    if (!isAuthenticated) {
      router.push({
        pathname: boatsetterPaths.logIn,
        query: {
          redirect_to: router.asPath.includes('?')
            ? `${router.asPath}&open_captain_modal=true`
            : `${router.asPath}?open_captain_modal=true`,
        },
      });
      closeModal();

      return;
    }

    setInviteFormVisible((isVisible) => !isVisible);
  }, [isAuthenticated, router]);

  const selectCaptain = useCallback(
    async (captainID, publicId) => {
      setBookingCaptainId(captainID);
      setBookingCaptainPublicId(publicId);
      if (onSelectCaptain) {
        onSelectCaptain(publicId);
      }
    },
    [setBookingCaptainId]
  );

  if (isLoading) {
    return <InfiniteLoader />;
  }

  return (
    <Container gap="20px">
      <SubHeading>Select your captain</SubHeading>
      <StyledInfiniteScroll
        dataLength={pages.length}
        next={fetchNextPage}
        height={window.innerHeight - 220}
        loader={<InfiniteLoader />}
        hasMore={hasNextPage}
      >
        <FlexCol gap="18px">
          {pages.map(({ data }) =>
            data.map(({ user: dataUser, id, new_captain_badge }) => (
              <Fragment key={id}>
                <FlexAlignCenterSpaceBetween gap="20px" pr="12px">
                  <CaptainUserCard
                    name={dataUser.first_name}
                    profilePhotoUrl={dataUser.picture.url}
                    rating={dataUser.reviews.rate}
                    reviewCount={dataUser.reviews.count}
                    userId={dataUser.id}
                    isNewCaptain={new_captain_badge}
                  />

                  <StyledButton
                    onClick={() => selectCaptain(id, dataUser.id)}
                    outline={bookingCaptainId !== id && bookingCaptainId !== dataUser.id}
                    text={bookingCaptainId !== id && bookingCaptainId !== dataUser.id ? 'Select' : 'Selected'}
                  />
                </FlexAlignCenterSpaceBetween>
                <StyledSeparator />
              </Fragment>
            ))
          )}
        </FlexCol>
      </StyledInfiniteScroll>

      {inviteFormVisible ? (
        <StyledInviteCaptainForm boatId={boatId} onCancel={toggleInviteForm} />
      ) : (
        <InviteLink onClick={toggleInviteForm}>Invite your own licensed captain</InviteLink>
      )}
    </Container>
  );
}

const StyledButton = styled(Button)`
  min-width: 110px;
  padding: 0 20px;
`;

export const StyledInfiniteScroll = styled(InfiniteScroll)`
  overflow-x: hidden !important;
  max-height: 90%;
`;

const Container = styled(FlexCol)`
  padding-top: 20px;
  ${mediaMMdMin} {
    width: 400px;
  }
`;

const StyledSeparator = styled(Separator)`
  margin: 0;
`;

const InviteLink = styled.div`
  font-size: 12px;
  font-weight: 500;
  line-height: 24px;
  color: #5e696a;
  text-decoration: underline;
  cursor: pointer;
`;

const StyledInviteCaptainForm = styled(InviteCaptainForm)`
  position: absolute;
  z-index: 5;
  left: 0;
  right: 0;
  bottom: 0;
`;
