import React, { useState } from 'react';
import styled from 'styled-components';
import ErrorMessage from 'components/ui/ErrorMessage/ErrorMessage';
import {
  InputTextContent,
  InputTextFull,
  InputTextLabel,
  InputTextPlaceholder,
  InputTextPrefix,
  InputTextWrapper,
  TextInputCell,
  TooltipWrapper,
} from '../styles';

interface InputOptions {
  maxLength?: number;
  defaultValue?: any;
  onChange?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  onKeyDown?: (e: React.SyntheticEvent<HTMLInputElement>) => void;
  min?: number;
  max?: number;
  inputMode?: any;
  pattern?: string;
}

interface Props extends InputOptions {
  label: string;
  name: string;
  disabled?: boolean;
  type?: string;
  tooltip?: React.ReactNode;
  errorMessage?: string;
  minLength?: number;
  bottomSpacing?: boolean;
  icon?: React.ReactNode;
  validate?: any;
  prefix?: any;
  inputRef?: React.Ref<any>;
  hasValue?: boolean;
  hasError?: boolean;
  error?: boolean;
  onFocus?: () => void;
  onBlur?: () => void;
  placeholder?: string;
  bold?: boolean;
  required?: boolean;
}

const InputText = (props: Props) => {
  const {
    label = '',
    name = '',
    disabled,
    type = 'text',
    min = null,
    maxLength = null,
    onChange,
    tooltip = null,
    errorMessage = null,
    onKeyDown,
    max = null,
    bottomSpacing = true,
    icon,
    pattern,
    prefix,
    inputRef,
    hasValue,
    hasError,
    error,
    defaultValue,
    onFocus,
    onBlur,
    placeholder,
    bold,
    inputMode,
    required,
  } = props;

  const opts: InputOptions = {};
  if (maxLength) {
    opts.maxLength = maxLength;
  }

  if (defaultValue) {
    opts.defaultValue = defaultValue;
  }

  if (onChange) {
    opts.onChange = (e) => onChange(e);
  }

  if (onKeyDown) {
    opts.onKeyDown = (e) => onKeyDown(e);
  }

  if (min) {
    opts.min = min;
  }

  if (max) {
    opts.max = max;
  }

  if (inputMode) {
    opts.inputMode = inputMode;
  }

  if (pattern) {
    opts.pattern = pattern;
  }

  const [isFocused, setFocused] = useState(false);

  const handleFocus = () => {
    setFocused(true);
    if (onFocus) {
      onFocus();
    }
  };

  const handleBlur = () => {
    setFocused(false);
    if (onBlur) {
      onBlur();
    }
  };

  return (
    <InputTextFull className={`${bottomSpacing ? 'InputTextBottomSpacing' : ''}`}>
      <InputTextContent>
        <TextInputCell>
          <InputTextWrapper>
            <InputTextLabel
              htmlFor={name}
              className={`${icon ? 'InputTextLabelWithIcon' : ''} ${
                hasValue || isFocused ? 'InputTextLabelIsFocused' : ''
              }`}
            >
              {label}
            </InputTextLabel>
            {prefix && (
              <InputTextPrefix className={`${hasValue || isFocused ? 'InputTextPrefixShow' : ''}`}>
                {prefix}
              </InputTextPrefix>
            )}
            {placeholder && (
              <InputTextPlaceholder className={`${!hasValue && isFocused ? 'InputTextPlaceholderShow' : ''}`}>
                {placeholder}
              </InputTextPlaceholder>
            )}
            <InputTextControl
              className={`InputTextWithAnimatedLabel ${prefix ? 'InputTextHasPrefix' : ''} ${
                icon ? 'InputTextWithIconBig' : ''
              } ${hasError ? 'InputTextInvalid' : ''} ${bold ? 'InputTextBold' : ''}`}
              type={type}
              name={name}
              id={name}
              disabled={disabled}
              required={required}
              ref={inputRef}
              autoComplete="off"
              onFocus={() => handleFocus()}
              onBlur={() => handleBlur()}
              {...opts}
            />
            {tooltip ? <TooltipWrapper>{tooltip}</TooltipWrapper> : null}
          </InputTextWrapper>
          <ErrorMessage
            className="InputTextError"
            error={error}
            message={errorMessage || `Please enter ${label || ''}`}
          />
        </TextInputCell>
      </InputTextContent>
    </InputTextFull>
  );
};

export default InputText;

const InputTextControl = styled.input`
  padding: 0.9em;
  width: 100%;
  -webkit-appearance: none;
  appearance: none;
  box-shadow: none;
  outline: none;
  line-height: normal;
  color: #17233c;
  background-color: #fff;
  &::placeholder {
    color: #b4b4b4;
  }

  border: solid 1px #dbdfe5;
  border-radius: 6px;
  height: 50px;
  transition: all 200ms;

  &.InputTextWithAnimatedLabel {
    font-size: 16px;
    padding-top: 32px;
    padding-left: 10px;
    &:-webkit-autofill,
    &:-webkit-autofill:hover,
    &:-webkit-autofill:active,
    &:-webkit-autofill:focus,
    &:-webkit-autofill::first-line {
      -webkit-text-fill-color: black;
      -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
      -webkit-box-shadow: 0 0 0 1000px #fff inset;
      transition: background-color 5000s ease-in-out 0s;
      font-weight: 600;
    }
  }

  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:active,
  &:-webkit-autofill:focus,
  &:-webkit-autofill::first-line {
    -webkit-text-fill-color: black;
    -webkit-box-shadow: 0 0 0px 1px rgba(0, 0, 0, 0.01) inset;
    -webkit-box-shadow: 0 0 0 1000px #fff inset;
    transition: background-color 5000s ease-in-out 0s;
  }

  &:-webkit-autofill::first-line {
    font-size: 16px;
    font-weight: 400;
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
    background-color: rgb(242, 242, 242);
    border-color: rgb(230, 230, 230);
  }

  &:focus {
    border: solid 1px #8e9697;
    &.InputTextInvalid {
      border: solid 1px #bc350a;
    }
  }

  &::-webkit-outer-spin-button,
  &::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  &[type='number'] {
    -moz-appearance: textfield;
  }

  &::-webkit-validation-bubble-message {
    display: none !important;
  }

  &.InputTextInvalid {
    border-color: #bc350a;
    color: #bc350a;
  }

  &.InputTextInvalid ~ i:before {
    color: #bc350a;
  }
`;
