import React from 'react';
import { useFormContext } from 'react-hook-form';
import InputTextComponent from 'components/ui/InputText/InputText';
import Tooltip from 'components/ui/Tooltip/Tooltip';
import { Box } from 'components/primitives/box';
import { valueEmpty } from '../../helpers';

const InputText = (props) => {
  const { name, required, min, errorMessage, max, minLength, validate, tooltipText } = props;
  const { pattern, ...rest } = props;

  const { register, errors, watch } = useFormContext();

  return (
    <InputTextComponent
      hasValue={!valueEmpty(watch(name))}
      hasError={errors[name]}
      error={errors[name]}
      tooltip={
        tooltipText ? (
          <Tooltip
            triggerWith={
              <Box pt="5px">
                <svg fill="#0e7873 " width={19} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1008 1008">
                  <path d="M504 85.6C273.9 85.6 85.6 273.9 85.6 504S273.9 922.4 504 922.4 922.4 734.1 922.4 504 734.1 85.6 504 85.6zm0 774c-196.6 0-355.6-158.9-355.6-355.6s159-355.6 355.6-355.6 355.6 159 355.6 355.6-159 355.6-355.6 355.6zm33.4-359.8v217.6c0 16.7-12.6 33.4-33.4 33.4-20.9 0-33.4-12.6-33.4-33.4V499.8c0-16.7 12.6-33.4 33.4-33.4 20.9-.1 33.4 16.7 33.4 33.4zm25.2-179.9c0 33.4-25.1 58.6-58.6 58.6s-58.6-25.1-58.6-58.6 25.1-58.6 58.6-58.6c33.4.1 58.6 25.1 58.6 58.6z" />
                </svg>
              </Box>
            }
          >
            <div style={{ maxWidth: '175px' }}>
              <div className="u-textGrayMedium u-textLeft u-sm-fsXxs u-fsSm">{tooltipText}</div>
            </div>
          </Tooltip>
        ) : null
      }
      inputRef={register({
        required: required && (errorMessage || 'This field is required'),
        validate,
        min,
        max,
        minLength,
        pattern,
      })}
      {...rest}
    />
  );
};

InputText.defaultProps = {
  label: '',
  name: '',
  placeholder: '',
  type: 'text',
  required: true,
  min: null,
  max: null,
  maxLength: null,
  gutter: true,
  tooltipText: '',
  errorMessage: null,
  minLength: null,
  bottomSpacing: true,
  validate: null,
  disabled: false,
};

export default InputText;
