import { FormProvider } from 'react-hook-form';
import styled from 'styled-components';
import InputText from 'components/forms/InputText';
import { Flex, FlexColAlignCenter } from 'components/primitives/flex';
import { useState } from 'react';
import useFormWithErrorTracking from 'hooks/useFormWithErrorTracking';
import { useInviteCaptainMutation } from 'components/pdp/v3/hooks';
import Button from 'components/ui/Button/Button';
import { CheckIcon } from '../icons';

export default function InviteCaptainForm({ boatId, onCancel, className = null }) {
  const [captainInvited, setCaptainInvited] = useState(false);
  const methods = useFormWithErrorTracking({
    mode: 'onSubmit',
    defaultValues: {
      first_name: '',
      email: '',
    },
  });

  const { watch } = methods;

  const inviteCaptainMutation = useInviteCaptainMutation();

  const processFormData = async (formValues) => {
    await inviteCaptainMutation.mutateAsync({
      boatId,
      body: formValues,
    });
    setCaptainInvited(true);
    setTimeout(onCancel, 2000);
  };

  return (
    <FormProvider {...methods}>
      <Container as="form" noValidate onSubmit={methods.handleSubmit(processFormData)} className={className}>
        {captainInvited && (
          <Success gap="0.5em">
            <StyledCheckIcon />
            <SuccessText>{`Your invitation to ${watch('first_name')} has been sent`}</SuccessText>
          </Success>
        )}

        <FormHeading>Invite a captain</FormHeading>
        <InputText
          label="Captain's name"
          name="first_name"
          type="text"
          id="first_name"
          required="This field is required."
        />
        <InputText
          label="Captain's email"
          name="email"
          type="email"
          id="captains_email"
          required="This field is required."
          pattern={/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,20}$/i}
        />
        <Flex gap="16px">
          <Button text="Send" type="submit" id="submitFormButton" />
          <Button justText text="Cancel" type="button" onClick={onCancel} />
        </Flex>
      </Container>
    </FormProvider>
  );
}

const Success = styled(FlexColAlignCenter)`
  min-height: 250px;
  position: absolute;
  left: 0;
  z-index: 22;
  background: #fafbfc;
  width: 100%;
  justify-content: center;
`;

const Container = styled.div`
  background: #fafbfc;
  padding: 2em 3.5em;

  & [class*='InputTextFull'] {
    margin-bottom: 20px;
  }
  & [class*='ErrorMessage'] {
    margin-top: 0;
  }
`;

const FormHeading = styled.div`
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 14px;
`;

const SuccessText = styled.div`
  font-weight: 600;
`;

const StyledCheckIcon = styled(CheckIcon)`
  width: 36px;
  height: 36px;
  path {
    fill: #2a8500;
  }
`;
