import { FlexAlignCenter } from 'components/primitives/flex';
import { useMemo } from 'react';
import styled from 'styled-components';
import { mediaMSmMax } from '../../../helpers/breakpoints';

interface Props {
  rating: number;
  reviewCount: number;
  short?: boolean;
  countText?: string;
  hideCountText?: boolean;
  fontSize?: string;
  reviewCountFontSize?: string;
}

export default function StarRating({
  rating = 0,
  reviewCount = 0,
  short = false,
  countText = 'booking',
  hideCountText = false,
  fontSize,
  reviewCountFontSize,
}: Props) {
  if (rating === 0) return null;

  return (
    <FlexAlignCenter gap="5px">
      {short ? <Star percentage={100} smaller /> : <Stars rating={rating} />}
      <RatingInfo fSize={fontSize}>
        <Amount data-testid="component-reviews-rating">{rating.toPrecision(2)}</Amount>
        <ReviewCount data-testid="component-reviews-rating-count" fSize={reviewCountFontSize}>
          ({reviewCount}
          {hideCountText ? '' : ` ${countText}${reviewCount > 1 ? 's' : ''}`})
        </ReviewCount>
      </RatingInfo>
    </FlexAlignCenter>
  );
}

export function Stars({ rating, smaller = false }) {
  const starsArray = useMemo(() => {
    const fullStarsCount = Math.floor(rating);
    const partialStarRatingAmount = rating - fullStarsCount;
    const emptyStarsCount = (partialStarRatingAmount > 0 ? 4 : 5) - fullStarsCount;

    return [
      ...Array(fullStarsCount).fill(100),
      ...(partialStarRatingAmount > 0 ? [partialStarRatingAmount * 100] : []),
      ...Array(emptyStarsCount).fill(0),
    ];
  }, [rating]);

  return (
    <FlexAlignCenter gap="3px" data-testid="pdp-star-rating-icons">
      {starsArray.map((percentage, index) => (
        <Star key={index} percentage={percentage} smaller={smaller} />
      ))}
    </FlexAlignCenter>
  );
}

const RatingInfo = styled(FlexAlignCenter)<{ fSize: string }>`
  gap: 0.3em;
  font-weight: 600;
  font-size: ${({ fSize }) => fSize || '14px'};
  ${mediaMSmMax} {
    padding-top: 3px;
  }
`;

const Amount = styled.div`
  line-height: 20px;
  font-weight: 700;
`;

const ReviewCount = styled.div<{ fSize: string }>`
  line-height: 22px;
  color: #8e9697;
  font-size: ${({ fSize }) => fSize};
`;

function Star({ percentage, smaller = false }) {
  const defs = (
    <defs>
      <linearGradient id={`grad${percentage}`} x1="0%" y1="0%" x2="100%" y2="0%">
        <stop
          offset={`${percentage}%`}
          style={{
            stopColor: '#72D4BA',
            stopOpacity: 1,
          }}
        />
        <stop
          offset={`${percentage}%`}
          style={{
            stopColor: '#DBDFE5',
            stopOpacity: 1,
          }}
        />
      </linearGradient>
    </defs>
  );

  return smaller ? (
    <svg width="16" height="16" fill="none" xmlns="http://www.w3.org/2000/svg">
      {defs}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.348 12.723a.6.6 0 0 0-.696 0L4.43 15.02a.6.6 0 0 1-.927-.648l1.109-4.029a.6.6 0 0 0-.2-.624L1.236 7.121a.6.6 0 0 1 .36-1.064l3.963-.135a.6.6 0 0 0 .547-.405l1.328-3.865a.6.6 0 0 1 1.134 0l1.328 3.865a.6.6 0 0 0 .547.405l3.963.135a.6.6 0 0 1 .36 1.064L11.586 9.72a.6.6 0 0 0-.199.624l1.11 4.029a.6.6 0 0 1-.928.648l-3.22-2.297Z"
        fill={`url(#grad${percentage})`}
      />
    </svg>
  ) : (
    <svg width="18" height="19" viewBox="0 0 18 19" fill="none" xmlns="http://www.w3.org/2000/svg">
      {defs}
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.34831 14.842C9.13988 14.6934 8.86012 14.6934 8.65168 14.842L4.19453 18.0198C3.7347 18.3476 3.11787 17.9165 3.26773 17.372L4.79177 11.8346C4.85478 11.6057 4.77685 11.3611 4.59299 11.2108L0.235385 7.64908C-0.193449 7.29857 0.0410886 6.60377 0.594624 6.58487L6.05044 6.39857C6.29903 6.39008 6.5166 6.22909 6.59742 5.99386L8.43255 0.651832C8.61808 0.111745 9.38192 0.111747 9.56745 0.651833L11.4026 5.99386C11.4834 6.22909 11.701 6.39008 11.9496 6.39857L17.4054 6.58487C17.9589 6.60377 18.1934 7.29857 17.7646 7.64908L13.407 11.2108C13.2232 11.3611 13.1452 11.6057 13.2082 11.8346L14.7323 17.372C14.8821 17.9165 14.2653 18.3476 13.8055 18.0198L9.34831 14.842Z"
        fill={`url(#grad${percentage})`}
      />
    </svg>
  );
}
